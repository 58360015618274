import { CrudModel } from './_crud';

export class AppointmentModel extends CrudModel {
  constructor() {
    super('app/appointment');
  }

  async createSlot(startDateTime: Date) {
    //tirar 3 horas porque esta salvando errado no banco de dados
    const dateBR = new Date(startDateTime);
    dateBR.setHours(dateBR.getHours() - 3);
    //console.log("logD create slot", dateBR);
    return this.post(null, 'createSlot', { startDateTime: dateBR });
  }

  async removeSlot(appointmentId: number) {
    return this.post(null, 'removeSlot', { id: appointmentId });
  }

  async getAvailableSlots(fromDate: Date, toDate: Date, psychologistUserId?: number) {
    return this.post(null, 'getAvailableSlots', { fromDate, toDate, psychologistUserId });
  }

  async getAppointmentsLatestEvents(page: number, history: boolean) {
    return this.post(null, 'getAppointmentsLatestEvents', { page, history });
  }

  async getSchedule(fromDate: Date, toDate: Date) {
    return this.post(null, 'getSchedule', { fromDate, toDate });
  }

  async getDetails(appointmentId: number) {
    return this.post(null, 'getDetails', { id: appointmentId });
  }

  async schedule(appointmentId: number, kind: 'SINGLE' | 'DOUBLE') {
    return this.post(null, 'schedule', { id: appointmentId, kind });
  }

  async sendReceipt(appointmentId: number, receiptUrl: string) {
    return this.post(null, 'sendReceipt', { id: appointmentId, receiptUrl });
  }

  async applyVoucher(appointmentId: number, voucherCode: string, appointmentType: string) {
    return this.post(null, 'applyVoucher', { id: appointmentId, voucherCode, appointmentType });    
  }

  async scheduleVoucher(appointmentId: number, kind: 'SINGLE' | 'DOUBLE', voucherCode: string, appointmentType: string) {
    return this.post(null, 'scheduleApplyVoucher', { id: appointmentId, kind, voucherCode, appointmentType });
  }

  async getPendingAppointments() {
    return this.post(null, 'getPendingAppointments', {});
  }

  async confirm(appointmentId: number, notes?: string) {
    return this.post(null, 'confirm', { id: appointmentId, notes });
  }

  async refuse(appointmentId: number, notes: string) {
    return this.post(null, 'refuse', { id: appointmentId, notes });
  }

  async getVideoRoom(appointmentId: number) {
    return this.post(null, 'getVideoRoom', { id: appointmentId });
  }

  async cancel(appointmentId: number, notes: string) {
    return this.post(null, 'cancel', { id: appointmentId, notes });
  }

  async saveStatus(appointmentId: number, statusStatus: any, statusReason: any, statusPriority: any, medicalRecord: string) {
    return this.post(null, 'saveStatus', { id: appointmentId, statusStatus, statusReason, statusPriority, medicalRecord });
  }
}

export default new AppointmentModel();
