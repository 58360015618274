
















import {
  Vue, Prop, Component, Watch,
} from "vue-property-decorator";
import AusterButton from "./AusterButton.vue";
import AusterInput from "./AusterInput.vue";

@Component({
  components: {
    AusterButton,
    AusterInput,
  },
})
export default class AusterInputModal extends Vue {
  dialogActive: boolean = false;

  @Prop({ default: false })
  value: boolean; // required by v-model

  @Prop({ default: "" })
  title: string;

  @Prop({ default: "" })
  subtitle: string;

  @Prop({ default: "" })
  warning: string;

  @Prop({ default: true })
  messageRequired: boolean;

  @Prop({ default: "Confirmar" })
  okButtonText: string;

  @Prop({ default: "primary" })
  okButtonKind: string;

  @Prop({ default: "Cancelar" })
  cancelButtonText: string;

  @Prop({ default: "secondary" })
  cancelButtonKind: string;

  @Prop({ default: false })
  hideTextArea: boolean;

  message: string = "";

  mounted() {
    this.dialogActive = this.value; // initialize state with prop
  }

  @Watch("value")
  updateDialogActive(isActive: boolean) {
    this.dialogActive = isActive; // update state when prop changes
  }

  confirm() {
    if (!this.hideTextArea && this.messageRequired && !this.message) {
      this.$vs.notification({ text: "O texto é obrigatório.", color: "#FF6767" });
      return;
    }
    this.$emit('confirmed', this.message);
    this.close();
  }

  close() {
    this.dialogActive = false;
    this.$emit('input', false);
  }
}
