

































import { Vue, Component } from "vue-property-decorator";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import AusterButton from "@/components/AusterButton.vue";
import AusterInputModal from "@/components/AusterInputModal.vue";

import appointmentModel from "@/models/appointment.model";

@Component({
  components: {
    AusterButton,
    ClipLoader,
    AusterInputModal,
  },
})
export default class Appointments extends Vue {
  pendingAppointments: any[] = [];
  loading = false;

  refuseModalActive: boolean = false;
  refusingAppointment: any = null;

  mounted() {
    this.loadPendingAppointments()
      .catch(error => console.error(error));
  }

  async loadPendingAppointments() {
    this.loading = true;
    try {
      this.pendingAppointments = await appointmentModel.getPendingAppointments();
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  }

  openReceipt(appointment: any) {
    window.open(appointment.receiptUrl, "_blank");
  }

  async acceptAppointment(appointment: any) {
    const loading = this.$vs.loading();
    try {
      await appointmentModel.confirm(appointment.id);
      this.$vs.notification({ text: "Consulta aceita com sucesso", color: "#2E7179" });
    } catch (error) {
      console.error(error);
      this.$vs.notification({ text: "Ocorreu um erro. Tente novamente.", color: "#FF6767" });
    }
    loading.close();
    await this.loadPendingAppointments();
  }

  requestRefusal(appointment: any) {
    this.refusingAppointment = appointment;
    this.refuseModalActive = true;
  }

  async refuseAppointment(refusalNotes: string) {
    const loading = this.$vs.loading();
    try {
      await appointmentModel.refuse(this.refusingAppointment.id, refusalNotes);
      this.$vs.notification({ text: "Consulta recusada com sucesso", color: "#2E7179" });
    } catch (error) {
      console.error(error);
      this.$vs.notification({ text: "Ocorreu um erro. Tente novamente.", color: "#FF6767" });
    }
    loading.close();
    await this.loadPendingAppointments();
  }
}
